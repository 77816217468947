<template>
  <div>
    <page-main>
      <div class="main-body-header" slot="header">
        <div class="main-title">
          菜单栏
          <span style="font-size: 10px">
            如需增加/删除栏目，请联系管理员<i class="el-icon-warning"></i
          ></span>
        </div>
        <el-button
          size="mini"
          type="primary"
          @click="addMenu('addParent', {})"
          :disabled="$store.state.user.userInfo.admin ? false : true"
          >新增栏目</el-button
        >
      </div>
      <div class="table-box" slot="body">
        <el-table
          :data="tableMenuList"
          class="table-wrape"
          style="width: 100%; height: 400px"
          height="400px"
          row-key="pageId"
          :loading="tableMenuLoading"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column label="栏目名称" prop="pageName"> </el-table-column>
          <el-table-column label="序号" width="50" prop="orderNum">
            <template slot-scope="scope">
              {{ scope.row.orderNum }}
            </template>
          </el-table-column>
          <el-table-column
            label="元素"
            width="120"
            :show-overflow-tooltip="true"
          >
            <template
              slot-scope="scope"
              v-if="scope.row.parentId && scope.row.cover"
            >
              <div class="demo-image__preview">
                <el-image
                  style="height: 45px; width: 45px"
                  :src="scope.row.cover"
                  :preview-src-list="[scope.row.cover]"
                >
                </el-image>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="path" label="跳转链接"> </el-table-column>
          <el-table-column label="备注" prop="remark"> </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <el-button
                :disabled="$store.state.user.userInfo.admin ? false : true"
                size="mini"
                type="text"
                @click="addMenu('addChild', scope.row)"
                >新增子项</el-button
              >
              <el-button
                size="mini"
                type="text"
                @click="addMenu('edit', scope.row)"
                >编辑</el-button
              >
              <el-popconfirm
                title="确定删除该菜单吗？"
                @confirm="deleteMenu(scope.row.pageId)"
              >
                <el-button
                  :disabled="$store.state.user.userInfo.admin ? false : true"
                  slot="reference"
                  size="mini"
                  type="text"
                  style="margin-left: 10px"
                  >删除</el-button
                >
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </page-main>

    <!-- 菜单新增 -->
    <el-dialog
      :title="dialogMenu.titile"
      :visible.sync="dialogMenu.visible"
      width="50%"
      top="5vh"
    >
      <el-form
        ref="menuForm"
        :model="menuForm"
        :rules="menuFormRules"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="菜单名" prop="pageName">
              <el-input
                class="form-table_inpse"
                v-model="menuForm.pageName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="跳转链接">
              <el-input
                :disabled="this.$store.state.user.userInfo.admin ? false : true"
                class="form-table_inpse"
                v-model="menuForm.path"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item
          label="元素"
          v-if="editType != 'addParent' && menuForm.parentId"
        >
          <el-row :gutter="10">
            <el-col :span="10">
              <div>
                <img
                  width="220"
                  height="170"
                  v-if="menuForm.cover"
                  :src="menuForm.cover"
                />
              </div>
            </el-col>
            <el-col :span="12">
              <el-upload
                ref="avatarUpload"
                class="upload-demo"
                drag
                accept="image/jpeg,image/png"
                :action="uploadUrl"
                :on-success="uploadSuccess"
                :headers="uploadHeaders"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或<em>点击上传</em>
                </div>
                <div class="el-upload__tip" slot="tip">
                  只能上传jpg/png文件,大小不超过15MB
                </div>
              </el-upload>
            </el-col>
          </el-row>
        </el-form-item>
        <el-row :gutter="10">
          <el-col :span="10">
            <el-form-item label="序号" prop="orderNum">
              <el-input
                type="number"
                class="form-table_inpse"
                v-model="menuForm.orderNum"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="备注">
              <el-input
                class="form-table_inpse"
                v-model="menuForm.remark"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogMenu.visible = false">取 消</el-button>
        <el-button type="primary" @click="submitFrom('menuForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import index from "./index.js";

export default index;
</script>
<style scoped lang="scss">
@import "../common.scss";
</style>
  