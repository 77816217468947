import PageMain from "@/components/pageMain/PageMain.vue";
import {
    getMenuList,//获取菜单
    addMenuList,//新增
    editMenuList,//编辑
    deleteMenu,//删除
} from "@/api/dataSet/menuSet";
import { uploadUrl } from "@/utils/constant.js";
import {
    treeDataTranslate, //放置成树型结构
  } from "@/utils/common"; //枚举数字返回文字
export default {
    components: {
        PageMain,
    },
    data() {
        return {
            tableMenuList: [],
            selectElmentId: null,
            tableMenuLoading:false,
            dialogMenu: {
                titile: '',
                visible: false
            },
            menuForm: {
                ancestors: "",
                children: [],
                createBy: '',
                cover: '',
                depth: 0,
                orderNum: 0,
                pageId: null,
                pageName: "",
                parentId: 0,
                parentName: "",
                path: "",
                remark: "",
                language:this.$route.query.lang*1
            },
            menuFormRules: {
                pageName: [
                    {
                        required: true,
                        message: "请输入菜单名",
                        trigger: "blur",
                    },
                ],
                orderNum: [
                    {
                        required: true,
                        message: "请输入序号",
                        trigger: "blur",
                    },
                ],
            },
            editType: '',
            uploadUrl,
            uploadHeaders: {},
        };
    },
    watch: {
        "$route.query.lang": {
            handler: function (val, oldVal) {
                this.getMenuList();
            },
        }
    },
    mounted() {
        this.uploadHeaders = { Authorization: this.$store.state.user.token };
        this.getMenuList();
    },
    methods: {
        //获取菜单
        async getMenuList() {
            this.tableMenuLoading = true;
            // 语言(0:汉语;1:英语)
            let res = await getMenuList(this.$route.query.lang*1);
            if (res.code === 200) {
                this.tableMenuList = treeDataTranslate(res.data, "pageId");
                this.tableMenuLoading = false;
            } else {
                this.tableMenuLoading = false;
            }
        },

        openRegister() {
            this.$router.push("/register");
        },
        cancelMenuSet() {
            // 关闭弹窗
            this.dialogMenuVisible = false;
            this.$emit("cancelMenuSet");
        },

        //提交
        async submitFrom(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let res = {};
                    if (this.menuForm.pageId) {
                        // 编辑
                        res = await editMenuList(this.menuForm);
                    } else {
                        // 新增
                        res = await addMenuList(this.menuForm);
                    }
                    if (res.code === 200) {
                        this.$message({
                            type: "success",
                            message: `编辑成功!`,
                        });
                        this.dialogMenu.visible = false;
                        this.getMenuList();
                    }
                }
            });
        },

        addMenu(type, row) {
            this.editType = type;
            this.dialogMenu = {
                visible: true,
                title: type === 'addParent' || type === 'addChild' ? '新增菜单' : '编辑菜单'
            };
            if (this.editType === "edit") {
                let menuForm = Object.assign({}, row);
                this.menuForm = menuForm;
            } else if (this.editType === "addChild") {
                this.$data.menuForm = this.$options.data.call(this).menuForm;
                this.menuForm.parentId = row.pageId;
                this.menuForm.depth = row.depth + 1;
                this.menuForm.createBy = this.$store.state.user.name;
            } else {
                this.$data.menuForm = this.$options.data.call(this).menuForm;
                this.menuForm.createBy = this.$store.state.user.name;
            }
        },

        //删除菜单
        async deleteMenu(id) {
            this.tableMenuLoading = true;
            let res = await deleteMenu(id);
            if (res.code === 200) {
                this.getMenuList();
            } else {
                this.$message.error('删除失败！')
            }
        },

        // 上传成功
        async uploadSuccess(res, file) {
            let fileSize = (file.size / 1024 / 1024);
            if (fileSize > 15) {
                this.$message.error('文件大小超过15MB,请重新上传！');
                return;
            }
            if (res.code !== 200) {
                this.$message.error('上传失败，请稍后再试');
                return;
            }
            try {
                this.menuForm.cover=res.data.url;
                this.$message.success('上传成功');
            } catch (e) {
                this.$message.error('上传失败，请稍后再试');
            }
            this.$refs.avatarUpload.clearFiles();
        },
    },
};